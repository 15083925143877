const PRODUCT_UNIT_NAMES = new Map([
  ["PIECE", "szt"],
  ["METER", "mb"],
  ["SQUARE", "m2"],
  ["LAMELLA", "mb lameli"],
]);
const PRODUCT_UNITS = [...PRODUCT_UNIT_NAMES.keys()];
type ProductUnit = (typeof PRODUCT_UNITS)[number];

export { ProductUnit, PRODUCT_UNITS, PRODUCT_UNIT_NAMES };
