import { useEffect, useState } from "react";
import { Select, Space } from "antd";
import {
  PRODUCT_FAMILY_NAMES,
  ProductFamily,
} from "../../../products/ProductFamily";
import { PRODUCT_TYPE_NAMES } from "../../../products/ProductType";
import Product from "app/products/Product";

const { Option, OptGroup } = Select;

export default function ProductChooser({
  value,
  values,
  onValueChange,
}: {
  value: Product | undefined;
  values: Product[];
  onValueChange: (changed: Product | undefined) => void;
}) {
  const [selectedFamily, setSelectedFamily] = useState<ProductFamily | null>(
    null
  );
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [productsByType, setProductsByType] = useState(null);

  const onProductChange = (id: string) => {
    const product = Object.values(values).find((p) => p.id === id);
    onValueChange(product);
  };

  function groupFamilyProducts(products: Product[], family: ProductFamily) {
    return products
      .filter((product) => product.family === family)
      .reduce((acc, obj) => {
        const key = obj.type;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
  }

  function onProductFamilyChange(family: ProductFamily) {
    setSelectedFamily(family);
    setProductsByType(groupFamilyProducts(values, family));
    setSelectedProduct(null);
  }

  useEffect(() => {
    if (value && values) {
      setSelectedFamily(value.family);
      setProductsByType(groupFamilyProducts(values, value.family));
      setSelectedProduct(value.id);
    }
  }, [value, values]);

  const familyOptions = [...PRODUCT_FAMILY_NAMES].map(([k, v]) => ({
    value: k,
    label: v,
  }));

  return (
    <Space wrap>
      <Select
        value={selectedFamily}
        options={familyOptions}
        style={{ width: 200 }}
        onChange={(currentFamily: ProductFamily) =>
          onProductFamilyChange(currentFamily)
        }
      />
      <Select
        value={selectedProduct}
        style={{ width: 200 }}
        onChange={(currentProduct) => onProductChange(currentProduct)}
      >
        {productsByType &&
          Object.keys(productsByType).map((type) => (
            <OptGroup label={PRODUCT_TYPE_NAMES.get(type)} key={type}>
              {productsByType[type].map((p) => (
                <Option key={p.id} value={p.id}>
                  {p.title}
                </Option>
              ))}
            </OptGroup>
          ))}
      </Select>
    </Space>
  );
}
