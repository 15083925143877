import { CustomerType } from "./CustomerType";

export default interface Customer {
  id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  creationDate: Date;
  street?: string;
  postalCode?: string;
  city?: string;
  phone1?: string;
  phone2?: string;
  type: CustomerType;
}

const getCustomerName = (customer: Customer): string => {
  return [customer.name, customer.firstName, customer.lastName]
    .filter((x) => x)
    .join(" ");
};

const getAddress = (customer: Customer): string => {
  const city = [customer.postalCode, customer.city].filter((x) => x).join(" ");
  return [customer.street, city].filter((x) => x).join(", ");
};

const getContact = (customer: Customer): string => {
  return [customer.phone1, customer.phone2].filter((x) => x).join(" ");
};

export { getCustomerName, getAddress, getContact };
