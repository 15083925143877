import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

export default function SaveButton({ onClick, disabled }: Props) {
  return (
    <Button
      icon={<CheckOutlined />}
      className="green-btn"
      onClick={onClick}
      disabled={disabled}
    >
      Zapisz
    </Button>
  );
}