import { Space } from "antd";
import CompletedStage from "./CompletedStage";
import CompletedExecution from "./completed-execution";

export default function CompletedExecutions({
  completed,
  onChange,
}: {
  completed: CompletedStage[];
  onChange: () => void;
}) {
  if (completed.length <= 0) {
    return null;
  }

  return (
    <>
      <h3>Wykonane</h3>
      <Space direction="vertical" size="middle" style={{width: "100%"}}>
        {completed.map((e) => (
          <CompletedExecution key={e.id} execution={e} onChange={onChange} />
        ))}
      </Space>
    </>
  );
}
