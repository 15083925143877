import { useEffect, useState } from "react";
import { Button, Dropdown, Space } from "antd";
import axios from "app/auth/axios-interceptor";

import { OrderStatus } from "app/orders/OrderStatus";
import OrderBase from "app/orders/OrderBase";
import Authorized from "app/auth/authorized";
import Roles from "app/auth/roles";

import OrderWithdrawButton from "./order/order-withdraw-button";
import { SendOutlined } from "@ant-design/icons";
import { MenuProps } from "antd/lib";
import { useAuth } from "app/auth/auth-provider";

export default function StatusButtons({
  orders,
  onChange,
}: {
  orders: OrderBase[];
  onChange: () => void;
}) {
  const [unifiedStatus, setUnifiedStatus] = useState<OrderStatus>();
  const [canWithdraw, setCanWithdraw] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (orders) {
      const unifiedStatus = getUnifiedStatus();
      setUnifiedStatus(unifiedStatus);
    }
  }, [orders]);

  useEffect(() => {
    const isEditor = auth.hasAnyRole(Roles.orderEdit);
    const isPendingWholesale = auth.hasAnyRole(Roles.orderCreate) && unifiedStatus === 'PENDING';
    setCanWithdraw(isEditor || isPendingWholesale);
  }, [auth, unifiedStatus]);

  const getUnifiedStatus = () => {
    const statuses = orders
      .map((o) => o.details.status)
      .filter((v, i, a) => a.indexOf(v) === i);

    if (statuses.length != 1) {
      return undefined;
    }

    return statuses[0];
  };

  const hasStatus = (expected?: OrderStatus) => {
    if (!unifiedStatus) {
      return false;
    }
    if (!expected) {
      return true;
    }
    return unifiedStatus === expected;
  };

  const hasRealizeDate = () => {
    return !orders.find((o) => !o.details.realizeDate);
  };

  const changeStatus = (orderId: string, status: OrderStatus) => {
    const uri = `/api/orders/${orderId}/status`;
    const data = `"${status}"`;
    const reqConfig = {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
    };

    return axios.post(uri, data, reqConfig);
  };

  const changeStatuses = (status: OrderStatus) => {
    const ids = orders.map((o) => o.id);
    Promise.all(ids.map((id) => changeStatus(id, status)))
      .then(onChange)
      .catch(console.error);
  };

  const statuses = [
    {
      title: "Zamów",
      status: "CREATED",
      active: () => hasStatus("PENDING"),
      roles: [...Roles.orderCreate, ...Roles.orderEdit],
    },
    {
      title: "Zleć",
      status: "SENT",
      active: () => hasStatus("CREATED") && hasRealizeDate(),
      roles: Roles.orderEdit,
    },
    {
      title: "Produkuj",
      status: "RECEIVED",
      active: () => hasStatus("SENT"),
      roles: Roles.orderEdit,
    },
    {
      title: "Gotowe",
      status: "PRODUCED",
      active: () => hasStatus("RECEIVED"),
      roles: Roles.orderEdit,
    },
  ];

  const items: MenuProps["items"] = statuses
    .filter((item) => auth.hasAnyRole(item.roles))
    .filter((item) => item.active())
    .map((item) => ({
      key: item.status,
      label: item.title,
    }));

  const onClick: MenuProps["onClick"] = (event) => {
    const status = event.key;
    changeStatuses(status);
  };

  return (
    <Space>
      <Dropdown menu={{ items, onClick }}>
        <Button className={"purple-btn"}>
          <Space>
            Status
            <SendOutlined />
          </Space>
        </Button>
      </Dropdown>


      <Authorized roles={[...Roles.orderCreate, ...Roles.orderEdit]}>
        {canWithdraw && <OrderWithdrawButton
          active={hasStatus() && !hasStatus("WITHDRAWN")}
          onConfirm={() => changeStatuses("WITHDRAWN")}
        />
        }
      </Authorized>
    </Space>
  );
}
