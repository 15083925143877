import { Space, Divider, Row, Col, List, Card, Select } from "antd";

import Authorized from "app/auth/authorized";
import { Link } from "react-router-dom";
import { Roles } from "app/auth/roles";
import OrderItemsTable from "./order-items-table";
import OrderProductStages from "./order-product-stages";
import { useEffect, useState } from "react";
import OrderProduct from "../OrderProduct";
import { useAuth } from "app/auth/auth-provider";
import { OrderStatus } from "app/orders/OrderStatus";

export default function OrderItemsCard({
  orderId,
  status,
  products,
  onItemsChange,
  productId,
}: {
  orderId: string;
  status: OrderStatus;
  products: OrderProduct[];
  productId?: string;
  onItemsChange: () => void;
}) {
  const [productFilter, setProductFilter] = useState<string | null>();
  const [canExecute, setCanExecute] = useState<boolean>(false);
  const [canModify, setCanModify] = useState<boolean>(false);

  const { customerId } = useAuth();

  useEffect(() => {
    setProductFilter(productId);
  }, [productId]);

  useEffect(() => {
    setCanExecute(status === "RECEIVED" || status === "PRODUCED");
    setCanModify(!customerId || status === "PENDING");
  }, [status]);

  function getProducts() {
    if (productFilter) {
      return products.filter((p) => p.id === productFilter);
    } else {
      return products;
    }
  }

  function cardTitle() {
    return (
      <Row gutter={8} align="middle">
        <Col xs={0} md={6} flex="none" style={{ fontSize: "16px" }}>
          Produkty
        </Col>
        <Col xs={24} md={18} flex="auto">
          <Select
            allowClear={true}
            options={products.map((p) => ({ value: p.id, label: p.title }))}
            style={{ width: 200 }} // TODO fit for mobile (hide?)
            onChange={(value) => setProductFilter(value)}
            value={productFilter}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Card
      title={cardTitle()}
      extra={
        canModify && (
          <Authorized roles={[...Roles.orderEdit, ...Roles.orderCreate]}>
            <Link to={`/zamowienia/${orderId}/produkty/dodaj`}>Dodaj</Link>
          </Authorized>
        )
      }
      styles={{ header: { backgroundColor: "#e6f7ff" } }}
    >
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {getProducts().map((product) => (
          <Space
            key={product.id}
            direction="vertical"
            size="middle"
            style={{ width: "100%" }}
          >
            <Divider style={{ fontWeight: "bold" }}>{product.title}</Divider>
            <Row>
              <Col xs={0} md={24}>
                <OrderItemsTable
                  key={product.id}
                  product={product}
                  orderId={orderId}
                  onChange={onItemsChange}
                  canModify={canModify}
                />
              </Col>
              <Col xs={24} md={0}>
                <List
                  dataSource={product.items.map((i) => i.description)}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </Col>
            </Row>
            {canExecute && (
              <Row wrap={false}>
                <Col flex="auto" />
                <Col flex="none">
                  <OrderProductStages
                    orderId={orderId}
                    productId={product.id}
                    stages={product.stages}
                  />
                </Col>
                <Col flex="auto" />
              </Row>
            )}
          </Space>
        ))}
      </Space>
    </Card>
  );
}
