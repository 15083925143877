import { Tag } from "antd";
import { ORDER_STATUS_NAMES, OrderStatus } from "./OrderStatus";

function getColor(status: OrderStatus) {
  switch (status) {
    case "SENT":
      return "blue";
    case "RECEIVED":
      return "orange";
    case "PRODUCED":
      return "green";
    case "WITHDRAWN":
      return "purple";
    case "CREATED":
    default:
      return "gray";
  }
}

export default function OrderStatusTag({ status }: { status: OrderStatus }) {
  const color = getColor(status);

  return (
    <div style={{ display: "flex" }}>
      <Tag color={color} key={status}>
        {ORDER_STATUS_NAMES.get(status)}
      </Tag>
    </div>
  );
}
