import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "app/auth/axios-interceptor";
import FabricForm from "app/fabrics/fabric-form";
import Fabric from "app/fabrics/Fabric";

function FabricEditPage() {

  const { fabricId } = useParams();
  const navigate = useNavigate();
  const [fabric, setFabric] = useState<Fabric>();

  useEffect(() => {
    axios.get<Fabric>(`/api/fabrics/${fabricId}`)
      .then((response) => {
        setFabric(response.data);
      })
      .catch(console.error);
  }, [fabricId]);

  function saveRecord(record: Fabric) {
    axios.put(`/api/fabrics/${fabricId}`, record)
      .then(() => {
        navigate("/tkaniny");
      })
      .catch(console.error);
  }

  function onSave(currentFabric: Fabric) {
    saveRecord(currentFabric);
  }

  function onDelete() {
    axios.delete(`/api/fabrics/${fabricId}`)
      .then(() => {
        navigate("/tkaniny");
      })
      .catch(console.error);
  }

  return (fabric &&
    <FabricForm
      value={fabric}
      onSave={(currentFabric) => onSave(currentFabric)}
      onDelete={() => onDelete()}
    />
  );
}

export default FabricEditPage;
