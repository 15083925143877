import { useNavigate, useSearchParams } from "react-router-dom";
import UserForm from "./user-form";
import User from "./User";
import axios from "app/auth/axios-interceptor";

export default function UserAddPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customerId") || undefined;

  const onSave = (user: User) => {
    axios
      .post(`/api/users`, user)
      .then(() => {
        navigate("/uzytkownicy");
      })
      .catch(console.error);
  };

  return (
    <>
      <UserForm customerId={customerId} onSave={onSave} />
    </>
  );
}
