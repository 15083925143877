import { useEffect, useState } from "react";
import { Space, Button, Modal, List, Input } from "antd";
import axios from "app/auth/axios-interceptor";
import DeleteButton from "app/common/delete-button";

interface Props {
  open: boolean;
  initial: string[];
  used: string[];
  onFinish: (changed: boolean) => void;
}

export default function FabricCategoriesModal({
  open,
  initial,
  used,
  onFinish,
}: Props) {
  const [categories, setCategories] = useState<string[]>([]);
  const [newCategory, setNewCategory] = useState<string>("");

  useEffect(() => {
    setCategories(initial);
  }, [initial]);

  function onCategoriesEditOk() {
    axios
      .post("/api/fabric-categories", categories)
      .then(() => {
        onFinish(true);
      })
      .catch(console.error);
  }

  function onCategoriesEditCancel() {
    onFinish(false);
  }

  function addCategory() {
    setCategories(categories.concat([newCategory]));
    setNewCategory("");
  }

  function removeCategory(category: string) {
    setCategories(categories.filter((c) => c !== category));
  }

  function canDelete(item: string) {
    return used.includes(item);
  }

  return (
    <Modal
      title="Kategorie tkanin"
      open={open}
      onOk={() => onCategoriesEditOk()}
      onCancel={() => onCategoriesEditCancel()}
    >
      <List
        itemLayout="horizontal"
        dataSource={categories}
        renderItem={(item) => (
          <List.Item
            actions={
              canDelete(item)
                ? []
                : [
                    <DeleteButton
                      key={item}
                      onClick={() => removeCategory(item)}
                    />,
                  ]
            }
          >
            {item}
          </List.Item>
        )}
      />
      <Space>
        <Input
          value={newCategory}
          onChange={(event) => setNewCategory(event.target.value)}
        />
        <Button onClick={() => addCategory()}>Dodaj</Button>
      </Space>
    </Modal>
  );
}
