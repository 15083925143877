import { Link } from "react-router-dom";
import { Steps } from "antd";
import StageStat from "../stages/StageStat";

interface Props {
  orderId: string;
  productId: string;
  stages: StageStat[];
}

type StepStatus = "wait" | "finish" | "process" | "error" | undefined;

export default function OrderProductStages({
  orderId,
  productId,
  stages,
}: Props) {
  function getStatus(stage: StageStat): StepStatus {
    const { done, total } = stage;

    if (done === 0) {
      return "wait";
    }
    if (done === total) {
      return "finish";
    }
    return "process";
  }

  function getDescription(stage: StageStat) {
    const { done, total } = stage;

    if (done === total) {
      return `Wykonano: ${done}`;
    }
    return `Zostało: ${total - done}`;
  }

  const steps = stages.map((stat) => ({
    key: stat.name,
    title: (
      <Link
        to={`/zamowienia/${orderId}/produkty/${productId}/etapy/${stat.name}`}
      >
        {stat.name}
      </Link>
    ),
    description: getDescription(stat),
    status: getStatus(stat),
  }));

  return <Steps direction="horizontal" items={steps} size="small" />;
}
