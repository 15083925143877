const ORDER_STATUS_NAMES = new Map([
  ["PENDING", "Oczekujące"],
  ["CREATED", "Nowe"],
  ["SENT", "Zlecone"],
  ["RECEIVED", "W produkcji"],
  ["PRODUCED", "Gotowe"],
  ["WITHDRAWN", "Wycofane"],
]);
const ORDER_STATUSES = [...ORDER_STATUS_NAMES.keys()];
type OrderStatus = (typeof ORDER_STATUSES)[number];

export { OrderStatus, ORDER_STATUSES, ORDER_STATUS_NAMES };
