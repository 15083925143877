import ReactDOM from 'react-dom/client';
import App from 'app/app';
import AuthProvider from 'app/auth/auth-provider';

const rootEl = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootEl);
root.render(
    <AuthProvider>
        <App />
    </AuthProvider>
);
