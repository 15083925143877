import { useState } from "react";
import { Button, Input, List, Modal, Space, Table } from "antd";
import { useAuth } from "app/auth/auth-provider";
import { Roles } from "app/auth/roles";
import axios from "app/auth/axios-interceptor";
import DeleteButton from "app/common/delete-button";
import ProductAttribute from "./ProductAttribute";
import { ColumnsType } from "antd/es/table";

interface Props {
    productId: string;
    attributes: ProductAttribute[];
    onChange: () => void;
}

export default function ProductAttributesTable({ productId, attributes, onChange }: Props) {

    const auth = useAuth();
    const [modalValuesVisible, setModalValuesVisible] = useState(false);
    const [attribute, setAttribute] = useState<ProductAttribute>();
    const [attributeValue, setAttributeValue] = useState<string>();

    function onEditValuesClick(currentAttribute: ProductAttribute) {
        setAttribute(currentAttribute);
        setModalValuesVisible(true);
    }

    function handleEditValues() {
        if (attribute) {
            const data = { values: attribute.values };

            axios.post(`/api/products/${productId}/attributes/${attribute.title}`, data)
                .then(() => {
                    setAttribute(undefined);
                    setModalValuesVisible(false);
                    onChange()
                })
                .catch(console.error);
        }
    }

    function addAttributeValue() {
        if (attribute && attributeValue) {
            attribute.values.push(attributeValue);
            setAttribute({ ...attribute });
        }
    }

    function removeAttributeValue(value: string) {
        if (attribute) {
            const values = attribute.values.filter((item) => item !== value);
            setAttribute({ ...attribute, values });
        }
    }

    const attributesColumns: ColumnsType<ProductAttribute> = [
        {
            title: "Nazwa",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Wartości",
            dataIndex: "values",
            key: "values",
            render: (values: string[]) => values?.join(", "),
        },
    ];

    if (auth.hasAnyRole(Roles.productEdit)) {
        attributesColumns.push({
            title: "Akcje",
            key: "actions",
            render: (record: ProductAttribute) => record.values &&
                <Button type="link" size="small" onClick={() => onEditValuesClick(record)}>Edytuj wartości</Button>
        });
    }

    return (
        <>
            <Table<ProductAttribute>
                rowKey={(record) => record.title}
                columns={attributesColumns}
                dataSource={attributes}
                pagination={false}
            />
            <Modal
                title="Wartości"
                open={modalValuesVisible}
                onOk={() => handleEditValues()}
                onCancel={() => setModalValuesVisible(false)}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={attribute?.values}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <DeleteButton key={item} onClick={() => removeAttributeValue(item)} />
                            ]}
                        >
                            {item}
                        </List.Item>
                    )}
                />
                <Space>
                    <Input value={attributeValue} onChange={(event) => setAttributeValue(event.target.value)} />
                    <Button onClick={() => addAttributeValue()}>Dodaj</Button>
                </Space>
            </Modal>
        </>
    );
}