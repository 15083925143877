import { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "app/auth/axios-interceptor";
import dayjs from "dayjs";

interface Props {
  date: dayjs.Dayjs;
}

interface ProductStat {
  name: string;
  quantity: number;
  children?: ProductStat[]
}

const columns = [
  {
    title: "Produkt",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Ilość",
    dataIndex: "quantity",
    key: "quantity",
  },
];

export default function ProductStatTable({ date }: Props) {

  const [stats, setStats] = useState<ProductStat[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const reqParams = {
      date: date.format("YYYY-MM-DD"),
    };

    axios.get<ProductStat[]>(`/api/stats/products`, { params: reqParams })
      .then((response) => setStats(response.data))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [date]);

  return (
    <Table<ProductStat>
      rowKey={(record) => record.name}
      dataSource={stats}
      columns={columns}
      loading={loading}
      pagination={false}
    />
  );
}
