import { Checkbox, Space, Tooltip } from "antd";
import axios from "app/auth/axios-interceptor";
import dayjs from "dayjs";
import DownloadButton from "app/common/download-button";
import OrderBase from "app/orders/OrderBase";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useState } from "react";
import Authorized from "app/auth/authorized";
import Roles from "app/auth/roles";

export default function DownloadOrdersButton({
  orders,
  onChange,
}: {
  orders: OrderBase[];
  onChange?: () => void;
}) {
  const [produce, setProduce] = useState(false);

  async function downloadOrders(ids: string[]) {
    const uri = `/api/orders/download?ids=${ids.join(",")}&produce=${!!produce}`;

    return axios.get(uri, { responseType: "blob" }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `zamowienia_${dayjs().format("YYYY-MM-DD_HH-MM")}.pdf`
      );
      document.body.appendChild(link);
      link.click();
    });
  }

  function onClick() {
    const ids = orders.map((o) => o.id);
    downloadOrders(ids)
      .then(() => {
        if (produce && onChange) {
          onChange();
        }
      })
      .catch((error) => console.log(error));
  }

  function onProduceDownloadedChange(e: CheckboxChangeEvent) {
    setProduce(e.target.checked);
  }

  return (
    <Space wrap>
      <DownloadButton onClick={() => onClick()} disabled={orders.length <= 0} />
      <Authorized roles={Roles.orderEdit}>
        <Tooltip title="Przy pobraniu wszystkie zlecone zamówienia zmienią status na 'W produkcji'">
          <Checkbox
            checked={produce}
            onChange={(e) => onProduceDownloadedChange(e)}
          >
            Produkuj pobrane
          </Checkbox>
        </Tooltip>
      </Authorized>
    </Space>
  );
}
