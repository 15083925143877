// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

.blue-btn {
  background-color: #bae0ff;
  color: #0958d9
}

.green-btn {
  background-color: #d9f7be;
  color: #389e0d
}

.purple-btn {
  background-color: #efdbff;
  color: #531dab
}

.yellow-btn {
  background-color: #fff1b8;
  color: #d48806
}

`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/app.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB;AACF;;AAEA;EACE,yBAAyB;EACzB;AACF;;AAEA;EACE,yBAAyB;EACzB;AACF;;AAEA;EACE,yBAAyB;EACzB;AACF","sourcesContent":["body {\n  margin: 0;\n}\n\n.blue-btn {\n  background-color: #bae0ff;\n  color: #0958d9\n}\n\n.green-btn {\n  background-color: #d9f7be;\n  color: #389e0d\n}\n\n.purple-btn {\n  background-color: #efdbff;\n  color: #531dab\n}\n\n.yellow-btn {\n  background-color: #fff1b8;\n  color: #d48806\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
