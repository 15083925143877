import { Card, Form, Button, Space, Select, Row, Col, DatePicker } from "antd";
import axios from "app/auth/axios-interceptor";
import Authorized from "app/auth/authorized";
import { Roles } from "app/auth/roles";
import PendingStage from "./PendingStage";
import User, { getFullName } from "app/users/User";
import dayjs, { Dayjs } from "dayjs";
import { useAuth } from "app/auth/auth-provider";
import StageInfo from "./StageInfo";
import RangeSelect from "app/common/range-select";
import { useEffect } from "react";

type PendingFormProps = {
  executions: {
    itemId: string;
    comment: string;
    itemTitle: string;
    amount: number;
  }[];
  username: string;
  date: Dayjs;
};

const { Meta } = Card;

export default function PendingExecutions({
  stage,
  pending,
  users,
  canSave,
  onChange,
}: {
  stage: StageInfo;
  pending: PendingStage[];
  users: User[];
  canSave: boolean;
  onChange: () => void;
}) {
  const hasExecutions = pending.length > 0;

  const [form] = Form.useForm<PendingFormProps>();
  const auth = useAuth();

  useEffect(() => {
    form.setFieldsValue({
      executions: pending,
      username: auth.username,
    });
  }, [pending, auth]);

  if (!pending || !hasExecutions) {
    return null;
  }

  function onSaveExecutions(values: PendingFormProps) {
    const data = { ...values, date: values.date.utc().format() };
    axios
      .post(
        `/api/orders/${stage.orderId}/products/${stage.productId}/stages/${stage.stageId}`,
        data
      )
      .then(() => {
        onChange();
      })
      .catch(console.error);
  }

  return (
    <>
      <h3>Do wykonania</h3>
      <Form
        form={form}
        onFinish={(values: PendingFormProps) => onSaveExecutions(values)}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form.List name="executions">
            {(fields) => (
              <Space direction="vertical" style={{ width: "100%" }}>
                {fields.map((field) => {
                  return (
                    <Card
                      size="small"
                      key={field.key}
                      style={{ backgroundColor: "#e6f4ff" }}
                    >
                      <Meta
                        title={pending[field.key].itemTitle}
                        description={pending[field.key].comment}
                        style={{ marginBottom: 10 }}
                      />
                      <Form.Item
                        name={[field.name, "amount"]}
                        style={{ marginBottom: 0 }}
                      >
                        <RangeSelect
                          maxValue={pending[field.key]?.amount || 0}
                        />
                      </Form.Item>
                    </Card>
                  );
                })}
              </Space>
            )}
          </Form.List>

          <Row gutter={8} justify="space-between">
            <Col flex="auto" style={{ marginBottom: "8px" }}>
              <Form.Item name="username" noStyle>
                <Select
                  options={users.map((e) => ({
                    key: e.username,
                    value: e.username,
                    label: getFullName(e),
                  }))}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="date" initialValue={dayjs()} noStyle>
                <DatePicker format={"DD.MM.YYYY"} />
              </Form.Item>
            </Col>
            <Authorized roles={Roles.pointEdit}>
              <Col>
                <Form.Item noStyle>
                  <Button type="primary" htmlType="submit" disabled={!canSave}>
                    Dodaj
                  </Button>
                </Form.Item>
              </Col>
            </Authorized>
          </Row>
        </Space>
      </Form>
    </>
  );
}
