import { createContext, useContext, useEffect, useState } from "react";
import Keycloak from 'keycloak-js';

type AuthContextType = {
  logout: () => void;
  isAuthenticated: () => boolean;
  hasAnyRole: (roles: string[]) => boolean;
  getAccessToken: () => Promise<string>;
  username: string;
  fullName: string;
  customerId?: string;
}

const AuthContext = createContext<AuthContextType | null>(null);

const keycloak = new Keycloak({
  url: KEYCLOAK_URL,
  realm: 'ravin',
  clientId: 'ravin'
});

async function initialize() {
  await keycloak.init({
    scope: 'roles',
    onLoad: 'login-required',
  })
    .catch(error => console.error('Failed to initialize adapter:', error));
}

function useProvideAuth() {

  const [username, setUsername] = useState<string>();
  const [fullName, setFullName] = useState<string>();
  const [customerId, setCustomerId] = useState<string | undefined>();

  useEffect(() => {
    keycloak.loadUserProfile()
      .then(profile => {
        setUsername(profile.username);

        const fullName = [profile.firstName, profile.lastName].filter(Boolean).join(" ");
        setFullName(fullName);

        if (profile.attributes && profile.attributes['customerId']) {
          const customerId = profile.attributes['customerId'] as string[];
          setCustomerId(customerId[0]);
        }
      })
      .catch(error => console.error(error));
  }, [keycloak]);

  function logout() {
    return keycloak.logout();
  }

  function isAuthenticated() {
    const auth = keycloak.authenticated;
    return auth || false;
  }

  function hasAnyRole(roles: string[]) {
    if (!isAuthenticated()) {
      return false;
    }

    if (!roles || roles.length === 0) {
      return true;
    }

    return roles.some(role => keycloak.hasRealmRole(role));
  }

  async function getAccessToken() {
    await keycloak.updateToken(30);
    const token = keycloak.token;
    if (!token) {
      throw new Error("Could not obtain token");
    }
    return token;
  }

  return {
    username, fullName, customerId, isAuthenticated, hasAnyRole, logout, getAccessToken
  };
}

export default function AuthProvider({ children }) {
  const auth = useProvideAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  const currentContext = useContext(AuthContext);
  if (!currentContext) {
    throw new Error("AuthContext not initialized");
  }
  return currentContext;
}

await initialize();