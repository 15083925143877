import { Button, Space, DatePicker } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;


export default function DatePickerDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) {
  return (
    <div style={{ padding: 8 }}>
      <Space direction="vertical">
        <RangePicker
          value={selectedKeys.map(k => dayjs(k))}
          onChange={(dates) => setSelectedKeys(dates?.map(d => d.format("YYYY-MM-DD")) || [])}
        />
        <Space>
          <Button
            type="link"
            onClick={() => clearFilters && clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Wyczyść
          </Button>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90 }}
          >
            OK
          </Button>
        </Space>
      </Space>
    </div>
  );
}
