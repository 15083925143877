import { List, Dropdown, Modal, Button, Card, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "app/auth/axios-interceptor";
import { Roles } from "app/auth/roles";
import { useAuth } from "app/auth/auth-provider";
import Authorized from "app/auth/authorized";
import OrderComment from "./OrderComment";
import { MenuProps } from "antd/lib";
import { getFullName } from "app/users/User";
import { useState } from "react";

const { confirm } = Modal;
const { TextArea } = Input;

interface Props {
  orderId: string;
  comments: OrderComment[];
  onCommentsChange: () => void;
}

export default function OrderCommentsCard({
  orderId,
  comments,
  onCommentsChange,
}: Props) {
  const auth = useAuth();

  const [modalOpen, seMoodalOpen] = useState(false);
  const [currentId, setCurrentId] = useState<string>();
  const [currentText, setCurrentText] = useState<string>("");
  const { username } = useAuth();

  function deleteComment(comment: OrderComment) {
    const url = `/api/orders/${orderId}/comments/${comment.id}`;

    axios
      .delete(url)
      .then(() => {
        onCommentsChange();
      })
      .catch(console.error);
  }

  function showDeleteCommentConfirm(comment: OrderComment) {
    confirm({
      title: "Czy na pewno usunąć komentarz?",
      icon: <ExclamationCircleOutlined />,
      okText: "Tak",
      okType: "danger",
      cancelText: "Nie",
      onOk: () => deleteComment(comment),
    });
  }

  function isSameUser(comment: OrderComment) {
    return comment.user.username === auth.username;
  }

  function getActions(comment: OrderComment) {
    const items: MenuProps["items"] = [];

    if (auth.hasAnyRole(Roles.orderView)) {
      items.push({
        key: "show",
        label: (
          <Button type="link" onClick={() => showModal(comment)}>
            Pokaż
          </Button>
        ),
      });
    }

    if (
      isSameUser(comment) &&
      auth.hasAnyRole([...Roles.orderEdit, ...Roles.orderCreate])
    ) {
      items.push({
        key: "delete",
        label: (
          <Button type="link" onClick={() => showDeleteCommentConfirm(comment)}>
            Usuń
          </Button>
        ),
        danger: true,
      });
    }

    return (
      <Dropdown menu={{ items }}>
        <Button>...</Button>
      </Dropdown>
    );
  }

  const showModal = (comment?: OrderComment) => {
    seMoodalOpen(true);
    if (comment) {
      setCurrentId(comment.id);
      setCurrentText(comment.text);
    }
  };

  const handleOk = () => {
    const data: OrderComment = {
      id: currentId,
      text: currentText,
      user: { username },
      date: new Date(),
    };

    let resp: Promise<void>;
    if (!currentId) {
      resp = axios.post(`/api/orders/${orderId}/comments`, data);
    } else {
      resp = axios.put(`/api/orders/${orderId}/comments/${currentId}`, data);
    }

    resp
      .then(() => {
        seMoodalOpen(false);
        setCurrentId(undefined);
        setCurrentText("");
      })
      .then(onCommentsChange)
      .catch(console.error);
  };

  const handleCancel = () => {
    seMoodalOpen(false);
    setCurrentId(undefined);
    setCurrentText("");
  };

  return (
    <Card
      title="Komentarze"
      extra={
        <Authorized roles={[...Roles.orderEdit, ...Roles.orderCreate]}>
          <Button type="link" onClick={() => showModal()}>
            Dodaj
          </Button>
          <Modal
            title="Komentarz"
            open={modalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <TextArea
              value={currentText}
              onChange={(e) => setCurrentText(e.target.value)}
            />
          </Modal>
        </Authorized>
      }
      styles={{ header: { backgroundColor: "#e6f7ff" } }}
    >
      {comments.length > 0 && (
        <List
          dataSource={comments}
          renderItem={(comment) => (
            <List.Item actions={[getActions(comment)]}>
              <List.Item.Meta
                title={comment.text}
                description={`${getFullName(comment.user)}, ${dayjs
                  .utc(comment.date)
                  .local()
                  .format("DD.MM.YYYY HH:mm")}`}
              />
            </List.Item>
          )}
        />
      )}
    </Card>
  );
}
