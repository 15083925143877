const PRODUCT_FAMILY_NAMES = new Map([
  ["BLIND", "Rolety"],
  ["NET", "Moskitiery"],
  ["HORIZONTAL", "Żaluzje poziome"],
  ["VERTICAL", "Żaluzje pionowe"],
  ["PLISSE", "Plisy"],
]);
const PRODUCT_FAMILIES = [...PRODUCT_FAMILY_NAMES.keys()];
type ProductFamily = (typeof PRODUCT_FAMILIES)[number];

export { ProductFamily, PRODUCT_FAMILIES, PRODUCT_FAMILY_NAMES };
