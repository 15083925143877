import { Button } from "antd";
import { FileOutlined } from "@ant-design/icons";

export default function DownloadButton({
  disabled,
  onClick,
}: {
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <Button
      icon={<FileOutlined />}
      className="blue-btn"
      onClick={onClick}
      disabled={disabled}
    >
      Pobierz
    </Button>
  );
}
