import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import axios from "app/auth/axios-interceptor";

import CustomerForm from "app/customers/customer-form";
import Customer from "app/customers/Customer";

export default function CustomerEditPage() {
  const navigate = useNavigate();

  function saveCustomer(newCustomer) {
    axios.post<Customer>(`/api/customers`, newCustomer)
      .then(() => {
        navigate("/klienci");
      })
      .catch(console.error);
  }

  return (
    <Row gutter={16}>
      <Col span={24} sm={12}>
        <CustomerForm onSave={(currentCustomer) => saveCustomer(currentCustomer)} />
      </Col>
    </Row>
  );
}
