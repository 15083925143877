import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "app/auth/axios-interceptor";

import CompletedExecutions from "./completed-executions";
import PendingExecutions from "./pending-executions";
import StageExecution from "./StageExecution";
import PendingStage from "./PendingStage";
import CompletedStage from "./CompletedStage";
import User from "app/users/User";
import StageInfo from "./StageInfo";
import { Space } from "antd";

export default function OrderProductStagePage() {
  const { orderId, productId, stageId } = useParams();

  if (!orderId || !stageId || !productId) {
    return null;
  }

  const stageInfo: StageInfo = { orderId, productId, stageId };

  const [completed, setCompleted] = useState<CompletedStage[]>([]);
  const [pending, setPending] = useState<PendingStage[]>([]);
  const [employees, setEmpoloyees] = useState<User[]>([]);
  const [canSave, setCanSave] = useState(false);

  function fetchExecutions() {
    axios
      .get<StageExecution>(
        `/api/orders/${stageInfo.orderId}/products/${stageInfo.productId}/stages/${stageInfo.stageId}`
      )
      .then((response) => {
        setCompleted(response.data.completed);
        setCanSave(response.data.isExecutable);
        setPending(response.data.pending);
        setEmpoloyees(response.data.employees);
      })
      .catch(console.error);
  }

  useEffect(() => {
    fetchExecutions();
  }, [orderId, productId, stageId]);

  return (
    <Space align="center" direction="vertical" style={{width: "100%"}}>
      <PendingExecutions
        stage={stageInfo}
        pending={pending}
        users={employees}
        canSave={canSave}
        onChange={fetchExecutions}
      />
      <CompletedExecutions completed={completed} onChange={fetchExecutions} />
    </Space>
  );
}
