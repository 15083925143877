import { Button, Modal } from "antd";
import { StopOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export default function OrderWithdrawButton({
  active,
  onConfirm,
}: {
  active: boolean;
  onConfirm: () => void;
}) {
  const showConfirmation = () => {
    confirm({
      title: "Czy na pewno wycofać wybrane zamówienia?",
      icon: <ExclamationCircleOutlined />,
      okText: "Tak",
      okType: "danger",
      cancelText: "Nie",
      onOk: () => onConfirm(),
    });
  };

  return (
    <Button
      danger
      onClick={showConfirmation}
      disabled={!active}
      icon={<StopOutlined />}
    >
      Wycofaj
    </Button>
  );
}
