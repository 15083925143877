import { useState } from "react";
import { Checkbox, InputNumber, Space } from "antd";

function FormulaField({ value = {}, onChange, ...rest }) {
  const [manual, setManual] = useState(false);
  const [manualValue, setManualValue] = useState();

  function onManualChange(newValue) {
    setManualValue(newValue);
    onChange(newValue);
  }

  return (
    <Space>
      <InputNumber {...rest} value={manual ? manualValue : value} disabled={!manual} onChange={currentValue => onManualChange(currentValue)} />
      <Checkbox onChange={() => setManual(!manual)}>Edytuj</Checkbox>
    </Space>
  );
}

export default FormulaField;