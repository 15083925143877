import { Button, Modal } from "antd";
import { StopOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;


export default function DeleteButton({ title, hint, onConfirm, disabled, onClick }: {
  title?: string;
  hint?: string;
  onConfirm?: () => void;
  disabled?: boolean;
  onClick?: () => void;
}) {

  function showConfirmation() {
    confirm({
      title: hint,
      icon: <ExclamationCircleOutlined />,
      okText: "Tak",
      okType: "danger",
      cancelText: "Nie",
      onOk: onConfirm,
    });
  }

  return (
    <Button
      danger
      icon={<StopOutlined />}
      onClick={onClick || showConfirmation}
      disabled={disabled}
    >
      {title || "Usuń"}
    </Button>
  );
}