import { Link } from "react-router-dom";
import { List } from "antd";
import dayjs from "dayjs";
import { CustomerOrder } from "./CustomerOrder";

interface Props {
  orders: CustomerOrder[];
}

export default function CustomerOrders({ orders }: Props) {
  return (
    <List<CustomerOrder>
      bordered
      style={{ background: "#fff" }}
      header={<div>Zamówienia</div>}
      dataSource={orders}
      renderItem={(order) => (
        <List.Item>
          <List.Item.Meta
            title={
              <Link to={`/zamowienia/${order.id}`}>{order.number}</Link>
            }
            description={dayjs.utc(order.creationDate).local().format()}
          />
        </List.Item>
      )}
    />
  );
}
