import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "app/auth/axios-interceptor";
import { useAuth } from "app/auth/auth-provider";

import OrderCommentForm from "./order-comment-form";
import OrderComment from "./OrderComment";

export default function OrderCommentEditPage() {
  const { orderId, commentId } = useParams();
  const navigate = useNavigate();

  const { username } = useAuth();
  const [comment, setComment] = useState<OrderComment>();
  const [canSave, setCanSave] = useState<boolean>(false);

  function saveComment(newComment: OrderComment) {
    axios
      .put(`/api/orders/${orderId}/comments/${commentId}`, newComment)
      .then(() => {
        navigate(`/zamowienia/${orderId}`);
      })
      .catch(console.error);
  }

  useEffect(() => {
    axios
      .get<OrderComment>(`/api/orders/${orderId}/comments/${commentId}`)
      .then((response) => {
        setComment(response.data);
        setCanSave(username === response.data.user.username);
      })
      .catch(console.error);
  }, [orderId, commentId, username]);

  return (
    <OrderCommentForm
      value={comment}
      canSave={canSave}
      onSave={(newComment: OrderComment) => saveComment(newComment)}
    />
  );
}
