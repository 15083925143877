import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

interface Props {
  onClick: () => void;
}

export default function ReturnButton({ onClick }: Props) {
  return (
    <Button
      type="text"
      icon={<ArrowLeftOutlined />}
      onClick={onClick} />
  );
}