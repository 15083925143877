import { useAuth } from "app/auth/auth-provider";

interface Props {
  children: React.ReactNode;
  roles?: string[];
}

function Authorized({ children, roles } : Props) {
  const auth = useAuth();
  const allowed = !roles || auth.hasAnyRole(roles);
  if (!allowed) {
    return null;
  }

  return children;
}

export default Authorized;