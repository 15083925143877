import { Collapse, List } from "antd";
import dayjs from "dayjs";
import OrderHistory from "./OrderHistory";
import { getFullName } from "app/users/User";

interface Props {
  history: OrderHistory[];
}

function OrderHistoryPanel({ history }: Props) {
  return (
    <Collapse
      items={[
        {
          key: "history",
          label: "Historia",
          children: (
            <List
              dataSource={history}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.event}
                    description={`${getFullName(item.user)}, ${dayjs
                      .utc(item.date)
                      .local()
                      .format("DD.MM.YYYY HH:mm")}`}
                  />
                </List.Item>
              )}
            />
          ),
        },
      ]}
    />
  );
}

export default OrderHistoryPanel;
