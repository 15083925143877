import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import axios from "app/auth/axios-interceptor";

import OrderHeader from "app/orders/order/order-header";
import OrderDetails from "./recipient/OrderDetails";

export default function OrderLayoutPage() {
  const { orderId } = useParams();
  const [info, setInfo] = useState<OrderDetails>();

  useEffect(() => {
    const url = `/api/orders/${orderId}/info`;
    axios
      .get<OrderDetails>(url)
      .then((response) => response.data)
      .then(setInfo)
      .catch(console.error);
  }, [orderId]);

  return (
    <>
      {info && <OrderHeader order={info} />}
      <Outlet />
    </>
  );
}
