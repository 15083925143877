import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

interface Props {
  onClick: () => void;
}

export default function PlusButton({ onClick }: Props) {
  return (
    <Button
      icon={<PlusOutlined />}
      className="green-btn"
      onClick={onClick}
    />
  );
}