import { useState } from "react";
import { Checkbox } from "antd";
import { CheckboxProps } from "antd/lib";

function CheckboxField({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (value: string) => void;
}) {
  const [checked, setChecked] = useState(value === "true");

  const onChecked: CheckboxProps["onChange"] = (e) => {
    setChecked(e.target.checked);
    onChange?.("" + e.target.checked);
  };

  return <Checkbox checked={checked} onChange={onChecked} />;
}

export default CheckboxField;
