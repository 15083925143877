import { useState } from "react";
import { Button, InputNumber, Modal, Table } from "antd";
import { useAuth } from "app/auth/auth-provider";
import { Roles } from "app/auth/roles";
import axios from "app/auth/axios-interceptor";
import ProductStage from "./ProductStage";
import { ColumnsType } from "antd/es/table";


interface Props {
    productId: string;
    stages: ProductStage[];
    onChange: () => void;
}

export default function ProductStagesTable({ productId, stages, onChange }: Props) {

    const auth = useAuth();
    const [modalPointsVisible, setModalPointsVisible] = useState(false);
    const [stage, setStage] = useState<ProductStage>();

    function onEditPointsClick(currentStage: ProductStage) {
        setStage(currentStage);
        setModalPointsVisible(true);
    }

    function onPointsChange(points: number) {
        if (stage) {
            setStage({ ...stage, points });
        }
    }

    function handleEditPoints() {
        if (!stage) {
            return;
        }

        const data = { points: stage.points };

        axios.post(`/api/products/${productId}/stages/${stage.name}`, data)
            .then(() => {
                setStage(undefined);
                setModalPointsVisible(false);
                onChange();
            })
            .catch(console.error);
    }

    const stagesColumns: ColumnsType<ProductStage> = [
        {
            title: "Nazwa",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Punkty",
            dataIndex: "points",
            key: "points",
        },
        {
            title: "Podział",
            dataIndex: "split",
            key: "split",
        },
    ];

    if (auth.hasAnyRole(Roles.productEdit)) {
        stagesColumns.push({
            title: "Akcje",
            key: "actions",
            render: (record: ProductStage) => <Button type="link" size="small" onClick={() => onEditPointsClick(record)}>Edytuj punkty</Button>
        });
    }

    return (
        <>
            <Table<ProductStage>
                rowKey={(record) => record.name}
                columns={stagesColumns}
                dataSource={stages}
                pagination={false}
            />
            <Modal
                title="Punkty"
                open={modalPointsVisible}
                onOk={() => handleEditPoints()}
                onCancel={() => setModalPointsVisible(false)}
            >
                <InputNumber
                    value={stage?.points}
                    min={1}
                    max={99}
                    onChange={(value) => onPointsChange(value || 0)}
                />
            </Modal>
        </>
    );
}