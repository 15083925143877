import { Select } from "antd";

interface RangeSelectProps {
  maxValue: number;
  value?: number;
}

const getRange = (max: number) => {
  return [...Array(max + 1).keys()];
};

const RangeSelect: React.FC<RangeSelectProps> = (props) => {
  const { maxValue, ...rest } = props;
  return (
    <Select
      options={getRange(maxValue).map((e) => ({ key: e, value: e }))}
      {...rest}
    />
  );
};

export default RangeSelect;
