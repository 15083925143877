import { useEffect, useState } from "react";
import { Space, Button, Dropdown, Tooltip } from "antd";
import axios from "app/auth/axios-interceptor";

import Authorized from "app/auth/authorized";
import { Roles } from "app/auth/roles";
import FabricCategoriesModal from "app/fabrics/fabric-categories-modal";
import Fabric from "app/fabrics/Fabric";
import FabricsTable from "./fabrics-table";
import AddButton from "app/common/add-button";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { MenuProps } from "antd/lib";
import DownloadFabricsButton from "./download-fabrics-button";

export default function FabricsPage() {
  const [loading, setLoading] = useState(false);
  const [fabrics, setFabrics] = useState<Fabric[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [usedCategories, setUsedCategories] = useState<string[]>([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedFabrics, setSelectedFabrics] = useState<string[]>([]);

  const navigate = useNavigate();

  function loadCategories() {
    axios
      .get<string[]>("/api/fabric-categories")
      .then((response) => {
        setCategories(response.data);
      })
      .catch(console.error);
  }

  function loadFabrics() {
    setLoading(true);
    axios
      .get<Fabric[]>(`/api/fabrics`)
      .then((response) => {
        setFabrics(response.data);
        const uniqueCategories = response.data
          .map((f) => f.category)
          .filter((x, i, a) => a.indexOf(x) === i);
        setUsedCategories(uniqueCategories);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    if (categories) {
      loadFabrics();
    }
  }, [categories]);

  function onCategoriesEditClick() {
    setModalVisible(true);
  }

  function onCategoriesClose(changed: boolean) {
    setModalVisible(false);
    if (changed) {
      loadCategories();
    }
  }

  function onCategoryChange() {
    loadFabrics();
  }

  function changeCategory(newCategory: string) {
    axios
      .post(`/api/fabric-categories?changeCategory`, {
        category: newCategory,
        ids: selectedFabrics,
      })
      .then(() => onCategoryChange())
      .catch(console.error);
  }

  const categoryMenu: MenuProps = {
    items:
      categories?.map((c) => ({
        key: c,
        label: c,
        disabled: selectedFabrics.length === 0,
      })) || [],
    onClick: ({ key }) => changeCategory(key),
  };

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space wrap>
          <Authorized roles={Roles.fabricEdit}>
            <AddButton onClick={() => navigate(`/tkaniny/dodaj`)} />
          </Authorized>
          <DownloadFabricsButton ids={selectedFabrics}/>
          <Authorized roles={Roles.fabricEdit}>
            <Dropdown menu={categoryMenu}>
              <Tooltip title="Zmień kategorię wszystkich zaznaczonych tkanin">
                <Button icon={<DownOutlined />} className="purple-btn">
                  Zmień
                </Button>
              </Tooltip>
            </Dropdown>
          </Authorized>
          <Authorized roles={Roles.fabricEdit}>
            <Button onClick={() => onCategoriesEditClick()} type="primary">
              Kategorie
            </Button>
          </Authorized>
        </Space>

        <FabricsTable
          fabrics={fabrics}
          loading={loading}
          onSelect={(values) => setSelectedFabrics(values)}
        />
      </Space>

      <FabricCategoriesModal
        open={isModalVisible}
        initial={categories}
        used={usedCategories}
        onFinish={(changed) => onCategoriesClose(changed)}
      />
    </>
  );
}
