import { Button } from "antd";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  to?: string;
  action?: () => void;
}

export default function LinkButton({ title, to, action }: Props) {

  const navigate = useNavigate();

  function onClick() {
    if (to) {
      navigate(to);
    } else if (action) {
      action();
    } else {
      console.warn("Link Button missing action")
    }
  }

  return (
    <Button type="link" onClick={() => onClick()}>{title}</Button>
  );
}