import { DatePicker, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";

import Authorized from "app/auth/authorized";
import SaveButton from "app/common/save-button";
import { Roles } from "app/auth/roles";
import { useEffect } from "react";
import OrderComment from "./OrderComment";
import dayjs from "dayjs";

export default function OrderCommentForm({
  value,
  canSave,
  onSave,
}: {
  value?: OrderComment;
  canSave: boolean;
  onSave: (comment: OrderComment) => void;
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (value) {
      if (value.date) {
        value.date = dayjs(value.date);
      }
      form.setFieldsValue(value);
    }
  }, [value]);

  return (
    <Form
      name="customer"
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      initialValues={{ remember: true }}
      onFinish={(values: OrderComment) => onSave(values)}
    >
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>
      <Form.Item hidden name={["user", "username"]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Użytkownik"
        name="fullName"
        rules={[{ required: true, message: "Wymagane pole" }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Data"
        name="date"
        rules={[{ required: true, message: "Wymagane pole" }]}
      >
        <DatePicker format="DD.MM.YYYY HH:mm" disabled />
      </Form.Item>
      <Form.Item
        label="Treść"
        name="text"
        rules={[{ required: true, message: "Wymagane pole" }]}
      >
        <TextArea />
      </Form.Item>
      <Authorized roles={[...Roles.orderEdit, ...Roles.orderCreate]}>
        <Form.Item wrapperCol={{ sm: { offset: 4, span: 20 } }}>
          <SaveButton onClick={form.submit} disabled={!canSave} />
        </Form.Item>
      </Authorized>
    </Form>
  );
}
