import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, Space } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";

import axios from "app/auth/axios-interceptor";
import Authorized from "app/auth/authorized";
import { Roles } from "app/auth/roles";
import AddButton from "app/common/add-button";
import SearchDropdown from "app/common/search-dropdown";
import Customer, { getAddress, getContact, getCustomerName } from "app/customers/Customer";
import { FilterValue } from "antd/es/table/interface";
import PagedModel from "app/common/PagedModel";
import TablePage from "app/common/TablePage";
import dayjs from "dayjs";


interface CustomerFilter {
  name?: string;
  address?: string;
  contact?: string;
  nip?: string;
}

const columns: ColumnsType<Customer> = [
  {
    title: "Nazwa",
    dataIndex: "name",
    filterDropdown: (props) => <SearchDropdown {...props} />,
    render: (_, customer) => <Link to={`/klienci/${customer.id}`}>{getCustomerName(customer)}</Link>
  },
  {
    title: "Adres",
    dataIndex: "address",
    filterDropdown: (props) => <SearchDropdown {...props} />,
    render: (_, customer) => getAddress(customer),
    responsive: ["md"]
  },
  {
    title: "Kontakt",
    dataIndex: "contact",
    filterDropdown: (props) => <SearchDropdown {...props} />,
    render: (_, customer) => getContact(customer),
    responsive: ["md"]
  },
  {
    title: "NIP",
    dataIndex: "nip",
    filterDropdown: (props) => <SearchDropdown {...props} />,
    responsive: ["md"]
  },
  {
    title: "Utworzono",
    dataIndex: "creationDate",
    render: (date: Date) => dayjs.utc(date).local().format("DD.MM.YYYY"),
    responsive: ["md"]
  }
];

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 10;

export default function CustomersPage() {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Customer[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: DEFAULT_PAGE + 1,
    pageSize: DEFAULT_PAGE_SIZE,
    pageSizeOptions: [DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE * 2, DEFAULT_PAGE_SIZE * 5],
    total: 0,
  });
  const [page, setPage] = useState<TablePage>({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE });
  const [filters, setFilters] = useState<CustomerFilter>({});

  function fetchData() {
    setLoading(true);

    const params = {
      page: page.page,
      size: page.size,
      name: filters.name,
      address: filters.address,
      contact: filters.contact,
      nip: filters.nip,
    };

    axios.get<PagedModel<Customer>>(`/api/customers`, { params })
      .then((response) => { 
        setData(response.data.content);
        setPagination({
          ...pagination,
          total: response.data.page.totalElements
        });
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchData();
  }, [page, filters]);

  function handleTableChange(currentPagination: TablePaginationConfig, currentFilters: Record<string, FilterValue | null>) {
    setPagination({
      ...pagination,
      ...currentPagination
    });

    // prevents infinite loop when setting total elements in Pagination element
    setPage({
      page: currentPagination.current ? currentPagination.current - 1 : DEFAULT_PAGE,
      size: currentPagination.pageSize || DEFAULT_PAGE_SIZE
    });

    setFilters({
      name: getFilterValue(currentFilters?.name),
      address: getFilterValue(currentFilters?.address),
      contact: getFilterValue(currentFilters?.contact),
      nip: getFilterValue(currentFilters?.nip),
    });
  }

  function getFilterValue(val: FilterValue | null): string | undefined {
    if (val && typeof val[0] === "string") {
      return val[0];
    }
    return undefined;
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Authorized roles={Roles.customerEdit}>
        <AddButton onClick={() => navigate("/klienci/dodaj")} />
      </Authorized>
      <Table<Customer>
        rowKey={(record) => record.id}
        columns={columns}
        loading={loading}
        pagination={pagination}
        dataSource={data}
        onChange={(currentPagination, currentFilters) => handleTableChange(currentPagination, currentFilters)}
      />
    </Space>
  );
}
