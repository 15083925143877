import { Col, Row, Space, Typography } from "antd";
import dayjs from "dayjs";

import OrderStatusTag from "app/orders/order-status-tag";
import OrderDetails from "./recipient/OrderDetails";
import { getCustomerName } from "app/customers/Customer";

const { Title } = Typography;

interface Props {
  order: OrderDetails;
}

export default function OrderHeader({ order }: Props) {
  return (
    <Row align="middle" justify="space-between" style={{ textAlign: "center" }}>
      <Col xs={24} md={9}>
        <Title level={4} type="secondary" style={{ margin: 0 }}>
          {dayjs.utc(order.creationDate).local().format("DD.MM.YYYY")}
        </Title>
      </Col>
      <Col xs={24} md={6}>
        <Title level={2} style={{ margin: 0 }}>
          <Space align="center">
            {order.number}
            <OrderStatusTag status={order.status} />
          </Space>
        </Title>
      </Col>
      <Col xs={24} md={9}>
        <Title level={4} type="secondary" style={{ margin: 0 }}>
          {getCustomerName(order.recipient.customer)}
        </Title>
      </Col>
    </Row>
  );
}
