import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from '@ant-design/icons';

interface Props {
  hierarchy: Hierarchy[];
}

export default function AppBreacrumb({ hierarchy }: Props) {

  document.title = hierarchy.slice(-1)[0]?.name || "Ravin";

  const items = [
    {
      title: <Link to="/">
        <HomeOutlined />
      </Link>
    },
  ];

  if (hierarchy.length > 0) {
    const extraItems = hierarchy.map((item) => ({
      title: <Link to={item.path}>
        {item.name}
      </Link>,
    }));

    items.push(...extraItems);
  }

  return (
    <Breadcrumb
      style={{ margin: '16px 0', fontSize: "1.3em" }}
      items={items} />
  );
}