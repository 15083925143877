import { useNavigate } from "react-router-dom";
import axios from "app/auth/axios-interceptor";
import FabricForm from "app/fabrics/fabric-form";
import Fabric from "app/fabrics/Fabric";

export default function FabricAddPage() {

  const navigate = useNavigate();

  function saveRecord(record) {
    axios.post(`/api/fabrics`, record)
      .then(() => {
        navigate("/tkaniny");
      })
      .catch(console.error);
  }

  function onSave(fabric: Fabric) {
    saveRecord(fabric);
  }

  return <FabricForm onSave={(currentFabric: Fabric) => onSave(currentFabric)} />;
}
