import { UserGroup } from "./UserGroup";

export default interface User {
  username: string;
  firstName: string;
  lastName?: string;
  groups: UserGroup[];
  customerId?: string;
}

const getFullName = (user: User): string => {
  return [user.firstName, user.lastName].filter((x) => x).join(" ");
};

export { getFullName };
