import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "app/auth/axios-interceptor";
import { useAuth } from "app/auth/auth-provider";
import OrderCommentForm from "./order-comment-form";
import OrderComment from "./OrderComment";

export default function OrderCommentAddPage() {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const { username, fullName } = useAuth();
  const [comment] = useState<OrderComment>({
    username,
    fullName,
    date: new Date(),
    text: "",
  });

  function saveComment(newComment: OrderComment) {
    axios
      .post(`/api/orders/${orderId}/comments`, newComment)
      .then(() => {
        navigate(`/zamowienia/${orderId}`);
      })
      .catch(console.error);
  }

  return (
    <OrderCommentForm
      value={comment}
      canSave={true}
      onSave={(newComment: OrderComment) => saveComment(newComment)}
    />
  );
}
