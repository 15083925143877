import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Space } from "antd";
import axios from "app/auth/axios-interceptor";

import OrderHistoryPanel from "./history/order-history-panel";
import OrderItemsCard from "./items/order-items-card";
import OrderCommentsCard from "./comments/order-comments-card";
import OrderDetailsCard from "./recipient/order-details-card";
import OrderButtons from "./order-buttons";
import Order from "../Order";

export default function OrderPage() {
  const { orderId } = useParams();

  const [searchParams] = useSearchParams();
  const product = searchParams.get("produkt") || undefined;

  if (!orderId) {
    return;
  }

  const [order, setOrder] = useState<Order>();

  function fetchOrder() {
    axios
      .get<Order>(`/api/orders/${orderId}`)
      .then((response) => {
        setOrder(response.data);
      })
      .catch(console.error);
  }


  useEffect(() => {
    fetchOrder();
  }, [orderId]);

  return (
    order && (
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <OrderDetailsCard
          id={orderId}
          details={order.details}
          onChange={() => fetchOrder()}
        />
        <OrderItemsCard
          orderId={orderId}
          products={order.products}
          status={order.details.status}
          onItemsChange={() => fetchOrder()}
          productId={product}
        />
        <OrderCommentsCard
          orderId={orderId}
          comments={order.comments}
          onCommentsChange={() => fetchOrder()}
        />
        <OrderHistoryPanel history={order.history} />
        <OrderButtons order={order} />
      </Space>
    )
  );
}
