import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import plPL from "antd/lib/locale/pl_PL";
import "antd/dist/reset.css";
import dayjs from "dayjs";
import "dayjs/locale/pl";
import utc from "dayjs/plugin/utc";

import "app/app.css";
import routes from "app/app-routes";
import { AxiosInterceptor } from "app/auth/axios-interceptor";
import { useAuth } from "app/auth/auth-provider";

dayjs.locale("pl");
dayjs.extend(utc);

export default function App() {
  const auth = useAuth();

  if (!auth.isAuthenticated()) {
    return <div>Unable to log in</div>;
  }

  const router = createBrowserRouter(routes);

  return (
    <AxiosInterceptor>
      <ConfigProvider locale={plPL}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </AxiosInterceptor>
  );
}
