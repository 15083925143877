import axios from "app/auth/axios-interceptor";
import { EditOutlined } from "@ant-design/icons";
import { Button, DatePicker, Modal, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import Authorized from "app/auth/authorized";
import Roles from "app/auth/roles";

export default function OrderRealizeDate({
  orderId,
  realizeDate,
  onChange,
}: {
  orderId: string;
  realizeDate?: Date;
  onChange: () => void;
}) {
  const [modalOpen, seMoodalOpen] = useState(false);
  const [selected, setSelected] = useState<Dayjs>(dayjs());

  useEffect(() => {
    if (realizeDate) {
      setSelected(dayjs.utc(realizeDate).local());
    } else {
      axios
        .get<string>("/api/order-details/next-work-day")
        .then((resp) => {
          setSelected(dayjs(resp.data));
        })
        .catch(console.error);
    }
  }, [realizeDate]);

  const showModal = () => {
    seMoodalOpen(true);
  };

  const handleOk = () => {
    const data = { realizeDate: selected };
    axios
      .put(`/api/orders/${orderId}/realize`, data)
      .then(() => seMoodalOpen(false))
      .then(onChange)
      .catch(console.error);
  };

  const handleCancel = () => {
    seMoodalOpen(false);
  };

  return (
    <Space>
      {realizeDate && dayjs.utc(realizeDate).local().format("DD.MM.YYYY HH:mm")}
      <Authorized roles={Roles.orderEdit}>
        <Button
          shape="circle"
          onClick={showModal}
          icon={<EditOutlined />}
          className="yellow-btn"
        />
        <Modal
          title="Data realizacji"
          open={modalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <DatePicker
            showTime
            allowClear={false}
            value={selected}
            onChange={(value) => {
              if (value) {
                setSelected(value);
              }
            }}
          />
        </Modal>
      </Authorized>
    </Space>
  );
}
