import { Button, Space, Select } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";

export default function SelectDropdown({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  values,
}: FilterDropdownProps & { values: string[] }) {
  return (
    <div style={{ padding: 8 }}>
      <Select
        value={selectedKeys[0]}
        onChange={(v: string) =>setSelectedKeys([v])}
        options={values.map(v => ({value: v, label: v}))}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="link"
          onClick={() => clearFilters && clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          Wyczyść
        </Button>
        <Button
          type="primary"
          onClick={() => confirm()}
          size="small"
          style={{ width: 90 }}
        >
          OK
        </Button>
      </Space>
    </div>
  );
}
