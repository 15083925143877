import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "./auth-provider";

const instance = axios.create();

interface Props {
  children: React.ReactNode;
}

const AxiosInterceptor = ({children}: Props) => {

  const [isSet, setIsSet] = useState(false)
  const auth = useAuth();

  useEffect(() => {
    instance.interceptors.request.use(
      async (config) => {
        const token = await auth.getAccessToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    setIsSet(true);
  }, [auth]);

  return isSet && children;
}

export default instance;

export { AxiosInterceptor };