import axios from "app/auth/axios-interceptor";
import DownloadButton from "app/common/download-button";
import dayjs from "dayjs";

export default function DownloadFabricsButton({ids}: {ids: string[]}) {
  function onClickDownload() {
    const uri = `/api/fabrics/download?ids=${ids.join(",")}`;

    axios
      .get(uri, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `tkaniny_${dayjs().format("YYYY-MM-DD_HH-mm")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(console.error);
  }

  return (
    <>
      <DownloadButton
        onClick={() => onClickDownload()}
        disabled={ids.length === 0}
      />
    </>
  );
}
