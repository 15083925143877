import { useEffect, useState } from "react";
import axios from "app/auth/axios-interceptor";
import { Tree } from "antd";
import { DataNode } from "antd/es/tree";
import { Link } from "react-router-dom";

interface ProductHierarchy {
  family: string;
  types: {
    type: string;
    products: {
      title: string;
      id: string;
    }[]
  }[]
}

export default function ProductsPage() {

  const [hierarchy, setHierarchy] = useState<ProductHierarchy[]>([]);

  useEffect(() => {
    axios.get<ProductHierarchy[]>('api/products?hierarchy')
    .then(result => setHierarchy(result.data))
    .catch(console.error);
  }, []);

  const treeData: DataNode[] = hierarchy.map(h => (
    {
      title: h.family,
      key: h.family,
      children: h.types.map(t => (
        {
          title: t.type,
          key: `${h.family}-${t.type}`,
          children: t.products.map(p => (
            {
              title: <Link to={`/produkty/${p.id}`}>{p.title}</Link>,
              key: p.id,
            }
          ))
        }
      )),
    }
  ));

  return (
    <Tree treeData={treeData} showLine autoExpandParent={true} defaultExpandAll={true}/>
  );
}
