import { useState } from "react";
import { Col, Row, DatePicker } from 'antd';

import dayjs from "dayjs";
import ProductStatTable from "./product-stat-table";
import PointStatTable from "./point-stat-table";

export default function StatsPage() {

  const [date, setDate] = useState(dayjs());

  const onDateChange = (value: dayjs.Dayjs | null) => {
    setDate(value || dayjs());
  };

  return (
    <div>
      <DatePicker
        picker="month"
        format="MMMM YYYY"
        value={date}
        onChange={onDateChange}
        allowClear={false}
        style={{ marginBottom: "16px" }}
      />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <PointStatTable date={date} />
        </Col>
        <Col xs={24} sm={12}>
          <ProductStatTable date={date} />
        </Col>
      </Row>
    </div>
  );
}
