import { useNavigate, useParams } from "react-router-dom";
import UserForm from "./user-form";
import User from "./User";
import axios from "app/auth/axios-interceptor";
import DeleteButton from "app/common/delete-button";
import Authorized from "app/auth/authorized";
import Roles from "app/auth/roles";

export default function UserEditPage() {
  const navigate = useNavigate();
  const { username } = useParams();

  const onSave = (user: User) => {
    axios
      .put(`/api/users/${user.username}`, user)
      .then(() => {
        navigate("/uzytkownicy");
      })
      .catch(console.error);
  };

  const resetPassword = () => {
    axios
      .put(`/api/users/${username}?reset`)
      .then(() => {
        navigate("/uzytkownicy");
      })
      .catch(console.error);
  };

  return (
    <>
      <UserForm username={username} onSave={onSave} />
      <Authorized roles={Roles.customerEdit}>
        <DeleteButton
          title="Resetuj hasło"
          hint="Czy na pewno zresetować hasło użytkownika?"
          onConfirm={resetPassword}
        />
      </Authorized>
    </>
  );
}
