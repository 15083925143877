import { Button, Dropdown } from "antd";
import { ClockCircleOutlined, DownOutlined } from "@ant-design/icons";

interface Props {
  onClick: () => void;
}

export default function FilterButton({ onClick }: Props) {

  const items = [
    {
      key: "filter",
      label: "Nieukończone",
      onClick,
      icon: <ClockCircleOutlined />
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
    >
      <Button
        icon={<DownOutlined />}
        className="yellow-btn"
      >
        Filtruj
      </Button>
    </Dropdown>
  );
}