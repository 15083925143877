export const Roles = {
  orderView: ["ROLE_ORDER_VIEW"],
  orderCreate: ["ROLE_ORDER_CREATE"],
  orderEdit: ["ROLE_ORDER_EDIT"],

  customerView: ["ROLE_CUSTOMER_VIEW"],
  customerEdit: ["ROLE_CUSTOMER_EDIT"],

  productView: ["ROLE_PRODUCT_VIEW"],
  productEdit: ["ROLE_PRODUCT_EDIT"],

  fabricView: ["ROLE_FABRIC_VIEW"],
  fabricEdit: ["ROLE_FABRIC_EDIT"],

  statView: ["ROLE_STAT_VIEW"],

  pointEdit: ["ROLE_POINT_SELF", "ROLE_POINT_OTHER"],

  userView: ["ROLE_USER_VIEW"],
  userEdit: ["ROLE_USER_EDIT"],
};

export default Roles;