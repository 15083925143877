import { useNavigate } from "react-router-dom";
import axios from "app/auth/axios-interceptor";
import OrderRecipientForm from "app/orders/order/recipient/order-recipient-form";
import OrderRecipient from "./recipient/OrderRecipient";
import { useAuth } from "app/auth/auth-provider";
import { useEffect, useState } from "react";
import Customer from "app/customers/Customer";

interface OrderCreated {
  id: string;
}

export default function OrderAddPage() {
  const navigate = useNavigate();
  const [recipient, setRecipient] = useState<OrderRecipient>();
  const auth = useAuth();

  useEffect(() => {
    if (auth.customerId) {
      axios
        .get<Customer>(`/api/customers/${auth.customerId}`)
        .then((response) => {
          const wholesaler: OrderRecipient = {
            type: "WHOLESALE",
            customer: response.data,
          };
          setRecipient(wholesaler);
        })
        .catch(console.error);
    }
  }, [auth]);

  function onFinish(values: OrderRecipient) {
    axios
      .post<OrderCreated>("/api/orders", values)
      .then((response) => {
        navigate(`/zamowienia/${response.data.id}`);
      })
      .catch(console.error);
  }

  return (
    <OrderRecipientForm
      recipient={recipient}
      onFinish={(values) => onFinish(values)}
    />
  );
}
