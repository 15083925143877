const USER_GROUP_NAMES = new Map([
  ["MANUFACTURE", "Produkcja"],
  ["OFFICE", "Biuro"],
  ["WHOLESALER", "Hurt"],
  ["ADMIN", "Administrator"],
  ["OWNER", "Właściciel"],
]);
const USER_GROUPS = [...USER_GROUP_NAMES.keys()];
type UserGroup = (typeof USER_GROUPS)[number];

export { UserGroup, USER_GROUPS, USER_GROUP_NAMES };
