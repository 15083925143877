import { useEffect } from "react";
import { DatePicker, Form, Input, Radio, Space } from "antd";
import dayjs from "dayjs";

import Authorized from "app/auth/authorized";
import { Roles } from "app/auth/roles";
import SaveButton from "app/common/save-button";
import DeleteButton from "app/common/delete-button";
import Customer from "app/customers/Customer";

function CustomerForm({
  value,
  onSave,
  canDelete,
  onDelete,
}: {
  value?: Customer;
  onSave?: (customer: Customer) => void;
  canDelete?: boolean;
  onDelete?: () => void;
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (value) {
      value.creationDate = dayjs(value.creationDate);
      form.setFieldsValue(value);
    }
  }, [value]);

  return (
    <Form
      name="customer"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onSave}
    >
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>
      <Form.Item
        label="Data utworzenia"
        name="creationDate"
        initialValue={dayjs()}
      >
        <DatePicker format="DD.MM.YYYY" disabled />
      </Form.Item>
      <Form.Item label="Rodzaj" name="type" initialValue={"RETAILER"}>
        <Radio.Group>
          <Radio.Button value="RETAILER">Detailczny</Radio.Button>
          <Radio.Button value="WHOLESALER">Hurtowy</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Nazwisko" name="lastName">
        <Input />
      </Form.Item>
      <Form.Item label="Imię" name="firstName">
        <Input />
      </Form.Item>
      <Form.Item label="Firma" name="name">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="NIP" name="nip">
        <Input />
      </Form.Item>
      <Form.Item label="Ulica" name="street">
        <Input />
      </Form.Item>
      <Form.Item label="Kod pocztowy" name="postalCode">
        <Input />
      </Form.Item>
      <Form.Item label="Miasto" name="city">
        <Input />
      </Form.Item>
      <Form.Item label="Telefon 1" name="phone1">
        <Input />
      </Form.Item>
      <Form.Item label="Telefon 2" name="phone2">
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: "email",
            message: "Niewłaściwy format Email",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Authorized roles={Roles.customerEdit}>
        <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
          <Space>
            <SaveButton onClick={form.submit} />
            {onDelete && (
              <DeleteButton
                hint="Czy na pewno usunąć klienta?"
                onConfirm={onDelete}
                disabled={!canDelete}
              />
            )}
          </Space>
        </Form.Item>
      </Authorized>
    </Form>
  );
}

export default CustomerForm;
