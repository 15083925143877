import { List, Space } from "antd";
import { useEffect, useState } from "react";
import axios from "app/auth/axios-interceptor";
import User, { getFullName } from "./User";
import { Link, useNavigate } from "react-router-dom";
import AddButton from "app/common/add-button";

export default function UsersPage() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    axios
      .get<User[]>("api/users")
      .then((result) => setUsers(result.data))
      .catch(console.error);
  }, []);

  return (
    <>
      <Space direction="vertical">
        <AddButton onClick={() => navigate("/uzytkownicy/dodaj")} />
        <List
          dataSource={users}
          renderItem={(user) => (
            <List.Item>
              <Link to={`/uzytkownicy/${user.username}`}>{getFullName(user)}</Link>
            </List.Item>
          )}
        />
      </Space>
    </>
  );
}
