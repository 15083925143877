import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DescriptionsProps, Descriptions } from "antd";
import axios from "app/auth/axios-interceptor";
import Product from "app/products/Product";
import { PRODUCT_FAMILY_NAMES } from "app/products/ProductFamily";
import { PRODUCT_UNIT_NAMES } from "app/products/ProductUnit";
import { PRODUCT_TYPE_NAMES } from "./ProductType";
import ProductAttributesTable from "app/products/product-attributes-table";
import ProductStagesTable from "app/products/product-stages-table";


export default function ProductPage() {
  const { productId } = useParams();
  if (!productId) {
    throw Error("Missing product ID");
  }

  const [product, setProduct] = useState<Product>();

  function loadProduct() {
    axios
      .get<Product>(`/api/products/${productId}`)
      .then((response) => setProduct(response.data))
      .catch(console.error);
  }

  useEffect(() => {
    if (productId) {
      loadProduct();
    }
  }, [productId]);

  const items: DescriptionsProps["items"] = product && [
    {
      key: "family",
      label: "Rodzina",
      children: PRODUCT_FAMILY_NAMES.get(product.family),
    },
    {
      key: "type",
      label: "Typ",
      children: PRODUCT_TYPE_NAMES.get(product.type),
    },
    {
      key: "name",
      label: "Nazwa",
      children: product.title,
    },
    {
      key: "unit",
      label: "Jednostka",
      children: PRODUCT_UNIT_NAMES.get(product.unit),
    },
    {
      key: "attributes",
      label: "Atrybuty",
      children: (
        <ProductAttributesTable
          productId={productId}
          attributes={product.attributes}
          onChange={loadProduct}
        />
      ),
    },
    {
      key: "stages",
      label: "Etapy",
      children: (
        <ProductStagesTable
          productId={productId}
          stages={product.stages}
          onChange={loadProduct}
        />
      ),
    },
  ];

  return <Descriptions items={items} column={1} bordered />;
}
