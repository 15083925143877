import { Table, Dropdown, Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "app/auth/axios-interceptor";

import { useAuth } from "app/auth/auth-provider";
import { Roles } from "app/auth/roles";
import LinkButton from "app/common/link-button";
import OrderProduct from "../OrderProduct";
import { MenuProps } from "antd/lib";
import OrderItemInfo from "./OrderItemInfo";

const { confirm } = Modal;

export default function OrderItemsTable({
  orderId,
  product,
  canModify,
  onChange,
}: {
  orderId: string;
  product: OrderProduct;
  canModify: boolean;
  onChange: () => void;
}) {
  const auth = useAuth();

  function addSide(itemId: string, side: string) {
    axios
      .post(`/api/orders/${orderId}/items/${itemId}/duplicateSide?side=${side}`)
      .then(() => {
        onChange();
      })
      .catch(console.error);
  }

  function duplicateItem(itemId: string) {
    axios
      .post(`/api/orders/${orderId}/items/${itemId}/duplicate`)
      .then(() => {
        onChange();
      })
      .catch(console.error);
  }

  function deleteItem(itemId: string) {
    axios
      .delete(`/api/orders/${orderId}/items/${itemId}`)
      .then(() => {
        onChange();
      })
      .catch(console.error);
  }

  function showDeleteConfirm(itemId: string) {
    confirm({
      title: "Czy na pewno usunąć produkt?",
      content:
        "Jeśli produkcja się rozpoczeła, spowoduje to usunięcie wykonanych etapów",
      icon: <ExclamationCircleOutlined />,
      okText: "Tak",
      okType: "danger",
      cancelText: "Nie",
      onOk: () => deleteItem(itemId),
    });
  }

  function getActions(item: OrderItemInfo) {
    const items: MenuProps["items"] = [];

    if (auth.hasAnyRole(Roles.orderView)) {
      items.push({
        key: "show",
        label: (
          <LinkButton
            title={canModify ? "Edytuj" : "Pokaż"}
            to={`/zamowienia/${orderId}/produkty/${item.id}`}
          />
        ),
      });
    }

    if (
      canModify &&
      auth.hasAnyRole([...Roles.orderEdit, ...Roles.orderCreate])
    ) {
      items.push({
        key: "duplicate",
        label: (
          <LinkButton title="Duplikuj" action={() => duplicateItem(item.id)} />
        ),
      });

      if (item.otherSide) {
        items.push({
          key: "side",
          label: (
            <LinkButton
              title={`Dodaj: ${item.otherSide}`}
              action={() => addSide(item.id, item.otherSide)}
            />
          ),
        });
      }

      items.push({
        key: "delete",
        label: (
          <LinkButton title="Usuń" action={() => showDeleteConfirm(item.id)} />
        ),
        danger: true,
      });
    }

    return (
      <Dropdown menu={{ items }}>
        <Button>...</Button>
      </Dropdown>
    );
  }

  const columns = product.columns.map((col, index) => ({
    title: col,
    key: col,
    render: (_, item: OrderItemInfo) => {
      const value = item.values[index];
      return value === 'true' ? 'X' : value === 'false' ? '' : value;
    },
  }));

  columns.push({
    title: "Akcje",
    key: "action",
    render: (item: OrderItemInfo) => getActions(item),
  });

  const dataSource = product.items;

  return (
    <Table
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
    />
  );
}
