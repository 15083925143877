import { useNavigate, useParams } from "react-router-dom";
import axios from "app/auth/axios-interceptor";

import OrderItemForm from "./order-item-form";
import OrderItem from "./OrderItem";
import { useEffect, useState } from "react";
import { OrderStatus } from "app/orders/OrderStatus";
import OrderDetails from "../recipient/OrderDetails";

export default function OrderItemAddPage() {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const [status, setStatus] = useState<OrderStatus>("PENDING");

  useEffect(() => {
    axios
      .get<OrderDetails>(`/api//orders/${orderId}/info`)
      .then((response) => {
        setStatus(response.data.status);
      })
      .catch(console.error);
  }, [orderId]);

  const onFinish = (item: OrderItem) => {
    axios
      .post(`/api/orders/${orderId}/items`, item)
      .then(() => {
        navigate(`/zamowienia/${orderId}`);
      })
      .catch(console.error);
  };

  return <OrderItemForm status={status} onSave={(item) => onFinish(item)} />;
}
