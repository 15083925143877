import { Space } from "antd";
import { useNavigate } from "react-router-dom";

import DownloadOrdersButton from "app/orders/download-orders-button";
import ReturnButton from "app/common/return-button";
import Order from "../Order";
import StatusButtons from "../status-buttons";

interface Params {
  order: Order;
}

export default function OrderButtons({ order }: Params) {
  const navigate = useNavigate();

  return (
    <Space wrap>
      <ReturnButton onClick={() => navigate("/zamowienia")} />
      <StatusButtons orders={[order]} onChange={() => navigate("/zamowienia")} />
      <DownloadOrdersButton orders={[order]} />
    </Space>
  );
}
