import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "app/auth/axios-interceptor";

import OrderRecipientForm from "./order-recipient-form";
import OrderRecipient from "./OrderRecipient";

export default function OrderRecipientEditPage() {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const [recipient, setRecipient] = useState<OrderRecipient>();

  useEffect(() => {
    if (orderId) {
      const url = `/api/orders/${orderId}/recipient`;
      axios
        .get<OrderRecipient>(url)
        .then((response) => {
          setRecipient(response.data);
        })
        .catch(console.error);
    }
  }, [orderId]);

  function onSave(newRecipient: OrderRecipient) {
    axios
      .put(`/api/orders/${orderId}/recipient`, newRecipient)
      .then(() => {
        navigate(`/zamowienia/${orderId}`);
      })
      .catch(console.error);
  }

  return (
    recipient && (
      <OrderRecipientForm
        recipient={recipient}
        onFinish={(newRecipient) => onSave(newRecipient)}
      />
    )
  );
}
