import { Modal, Button, Dropdown, Card, Form, Row, Col, Space } from "antd";
import { ExclamationCircleOutlined, DownOutlined } from "@ant-design/icons";
import axios from "app/auth/axios-interceptor";

import Authorized from "app/auth/authorized";
import { Roles } from "app/auth/roles";
import CompletedStage from "./CompletedStage";
import { getFullName } from "app/users/User";
import { MenuProps } from "antd/lib";
import dayjs from "dayjs";

const { confirm } = Modal;
const { Meta } = Card;

export default function CompletedExecution({
  execution,
  onChange,
}: {
  execution: CompletedStage;
  onChange: () => void;
}) {
  const [form] = Form.useForm();
  form.setFieldsValue(execution);

  function getWithdrawAction(execution: CompletedStage) {
    const items: MenuProps["items"] = execution.canWithdraw.map((user) => ({
      key: user.username,
      label: getFullName(user),
    }));

    const onClick: MenuProps["onClick"] = (event) =>
      showWithdrawConfirm(event.key);

    return (
      <Dropdown key={`withdraw_${execution.id}`} menu={{ items, onClick }}>
        <Button danger>
          <Space>
            Wycofaj
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  }

  function withdraw(username: string) {
    axios
      .put(`/api/executions/${execution.id}?withdraw=${username}`)
      .then(() => onChange())
      .catch(console.error);
  }

  function showWithdrawConfirm(username: string) {
    confirm({
      title: `Czy na pewno wycofać wykonanie?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Tak",
      okType: "danger",
      cancelText: "Nie",
      onOk: () => withdraw(username),
    });
  }

  function getJoinAction(execution: CompletedStage) {
    if (execution.canJoin.length <= 0) {
      return null;
    }

    const items: MenuProps["items"] = execution.canJoin.map((executor) => ({
      key: executor.username,
      label: getFullName(executor),
    }));

    const onClick: MenuProps["onClick"] = (event) => join(execution, event.key);

    return (
      <Dropdown key={`join_${execution.id}`} menu={{ items, onClick }}>
        <Button style={{ width: "100%" }}>
          Dołącz <DownOutlined />
        </Button>
      </Dropdown>
    );
  }

  function join(execution: CompletedStage, username: string) {
    axios
      .put(`/api/executions/${execution.id}?join=${username}`)
      .then(() => onChange())
      .catch(console.error);
  }

  return (
    <Form key={execution.id} form={form}>
      <Card size="small" style={{ backgroundColor: "#f6ffed", width: "100%" }}>
        <Meta
          title={execution.itemTitle}
          description={execution.comment}
          style={{ marginBottom: 10 }}
        />

        <Row gutter={[8, 8]} align="middle" justify="space-between">
          <Col>{execution.executors.map(getFullName).join(", ")}</Col>
          <Col>{dayjs(execution.date).format("DD.MM.YYYY")}</Col>
          <Col>{execution.amount}</Col>
          <Authorized roles={Roles.pointEdit}>
            <Col>
              <Space>
                {getJoinAction(execution)}
                {getWithdrawAction(execution)}
              </Space>
            </Col>
          </Authorized>
        </Row>
      </Card>
    </Form>
  );
}
