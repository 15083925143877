import { useEffect, useState } from "react";
import axios from "app/auth/axios-interceptor";
import { useNavigate, useParams } from "react-router-dom";

import OrderItemForm from "./order-item-form";
import OrderItem from "./OrderItem";
import { OrderStatus } from "app/orders/OrderStatus";
import OrderDetails from "../recipient/OrderDetails";

export default function OrderItemEditPage() {
  const { orderId, itemId } = useParams();
  const navigate = useNavigate();

  const [item, setItem] = useState<OrderItem>();
  const [status, setStatus] = useState<OrderStatus>("PENDING");

  useEffect(() => {
    axios
      .get<OrderItem>(`/api/orders/${orderId}/items/${itemId}`)
      .then((response) => {
        setItem(response.data);
      })
      .catch(console.error);

    axios
      .get<OrderDetails>(`/api//orders/${orderId}/info`)
      .then((response) => {
        setStatus(response.data.status);
      })
      .catch(console.error);
  }, [itemId, orderId]);

  const onFinish = (newItem) => {
    axios
      .put(`/api/orders/${orderId}/items/${itemId}`, newItem)
      .then(() => {
        navigate(`/zamowienia/${orderId}`);
      })
      .catch(console.error);
  };

  return (
    <OrderItemForm
      value={item}
      status={status}
      onSave={(newItem) => onFinish(newItem)}
    />
  );
}
