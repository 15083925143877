import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Space, Table, Typography } from "antd";
import type { TablePaginationConfig, ColumnsType } from "antd/es/table";
import axios from "app/auth/axios-interceptor";
import dayjs from "dayjs";

import Authorized from "app/auth/authorized";
import { Roles } from "app/auth/roles";
import AddButton from "app/common/add-button";
import DownloadOrdersButton from "app/orders/download-orders-button";
import StatusButtons from "./status-buttons";
import FilterButton from "app/orders/filter-button";
import OrderRow from "./OrderRow";
import PagedModel from "app/common/PagedModel";
import { ORDER_STATUS_NAMES, OrderStatus } from "./OrderStatus";
import SearchDropdown from "app/common/search-dropdown";
import DatePickerDropdown from "../common/date-picker-dropdown";
import OrderStatusTag from "./order-status-tag";
import { PRODUCT_FAMILY_NAMES } from "app/products/ProductFamily";
import { FilterValue } from "antd/es/table/interface";
import TablePage from "app/common/TablePage";
import Customer, { getCustomerName } from "app/customers/Customer";

const { Text } = Typography;

interface OrderFilter {
  number?: string;
  customer?: string;
  subcontract?: string;
  statuses?: string; // query param
  status?: string[]; // currently filtered
  families?: string; // query param
  product?: string[]; // currently filtered
  creationDateFrom?: string;
  creationDateTo?: string;
  realizeDateFrom?: string;
  realizeDateTo?: string;
}

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 10;

const incompleteStatuses = ["CREATED", "SENT", "RECEIVED"];

export default function OrdersPage() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OrderRow[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: DEFAULT_PAGE + 1,
    pageSize: DEFAULT_PAGE_SIZE,
    pageSizeOptions: [
      DEFAULT_PAGE_SIZE,
      DEFAULT_PAGE_SIZE * 2,
      DEFAULT_PAGE_SIZE * 5,
    ],
    total: 0,
  });
  const [page, setPage] = useState<TablePage>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const [filters, setFilters] = useState<OrderFilter>({});
  const [selectedOrders, setSelectedOrders] = useState<OrderRow[]>([]);

  const columns: ColumnsType<OrderRow> = [
    {
      title: "Numer",
      dataIndex: ["details", "number"],
      key: "number",
      filterDropdown: (props) => <SearchDropdown {...props} />,
      render: (text, record) => (
        <Link to={`/zamowienia/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Klient",
      dataIndex: ["details", "recipient", "customer"],
      key: "customer",
      filterDropdown: (props) => <SearchDropdown {...props} />,
      render: (customer: Customer) => getCustomerName(customer),
    },
    {
      title: "Podzlecenie",
      dataIndex: ["details", "recipient", "subcontract"],
      key: "subcontract",
      filterDropdown: (props) => <SearchDropdown {...props} />,
      responsive: ["lg"],
    },
    {
      title: "Wystawienie",
      dataIndex: ["details", "creationDate"],
      key: "creationDate",
      filterDropdown: (props) => <DatePickerDropdown {...props} />,
      render: (text: string) => dayjs.utc(text).local().format("DD.MM.YYYY"),
      responsive: ["md"],
    },
    {
      title: "Realizacja",
      dataIndex: ["details", "realizeDate"],
      key: "realizeDate",
      filterDropdown: (props) => <DatePickerDropdown {...props} />,
      render: (text: string) =>
        text && dayjs.utc(text).local().format("DD.MM.YYYY HH:mm"),
      responsive: ["md"],
    },
    {
      title: "Status",
      dataIndex: ["details", "status"],
      key: "status",
      filters: [...ORDER_STATUS_NAMES].map(([k, v]) => ({
        text: v,
        value: k,
      })),
      filteredValue: filters.status || undefined,
      render: (status: OrderStatus) => <OrderStatusTag status={status} />,
      responsive: ["md"],
    },
    {
      title: "Produkty",
      dataIndex: "product",
      key: "product",
      render: (_, record) => (
        <Space direction="vertical">
          {record.products.map((up) => (
            <Text key={up}>{up}</Text>
          ))}
        </Space>
      ),
      filters: [...PRODUCT_FAMILY_NAMES].map(([k, v]) => ({
        text: v,
        value: k,
      })),
      filteredValue: filters.product || undefined,
      responsive: ["lg"],
    },
  ];

  useEffect(() => {
    fetchData();
  }, [page, filters]);

  function fetchData() {
    setLoading(true);

    const params = {
      page: page.page,
      size: page.size,
      ...filters,
    };

    axios
      .get<PagedModel<OrderRow>>(`/api/orders`, { params })
      .then((response) => {
        setData(response.data.content);
        setPagination({
          ...pagination,
          total: response.data.page.totalElements,
        });
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function handleTableChange(
    currentPagination: TablePaginationConfig,
    currentFilters: Record<string, FilterValue | null>
  ) {
    setPagination({
      ...pagination,
      ...currentPagination,
    });

    // prevents infinite loop when setting total elements in Pagination element
    setPage({
      page: currentPagination.current
        ? currentPagination.current - 1
        : DEFAULT_PAGE,
      size: currentPagination.pageSize || DEFAULT_PAGE_SIZE,
    });

    setFilters({
      number: getFilterValue(currentFilters?.number),
      customer: getFilterValue(currentFilters?.customer),
      subcontract: getFilterValue(currentFilters?.subcontract),
      statuses: getFilterValues(currentFilters?.status),
      families: getFilterValues(currentFilters?.product),
      creationDateFrom: getFilterDate(currentFilters?.creationDate, 0),
      creationDateTo: getFilterDate(currentFilters?.creationDate, 1),
      realizeDateFrom: getFilterDate(currentFilters?.realizeDate, 0),
      realizeDateTo: getFilterDate(currentFilters?.realizeDate, 1),
    });
  }

  function getFilterValue(val: FilterValue | null): string | undefined {
    if (val && typeof val[0] === "string") {
      return val[0];
    }
    return undefined;
  }

  function getFilterValues(val: FilterValue | null): string | undefined {
    if (val && Array.isArray(val)) {
      return val.join(",");
    }
    return undefined;
  }

  function getFilterDate(
    val: FilterValue | null,
    index: number
  ): string | undefined {
    if (val && typeof val[index] === "string") {
      return dayjs(val[index]).format("YYYY-MM-DD");
    }
    return undefined;
  }

  function showIncomplete() {
    setFilters({
      ...filters,
      statuses: incompleteStatuses.join(","),
    });
  }

  function onSelect(ids: React.Key[]) {
    const selected = data.filter((o) => ids.includes(o.id));
    setSelectedOrders(selected);
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => onSelect(selectedRowKeys),
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={16}>
      <Space wrap>
        <Authorized roles={[...Roles.orderCreate, ...Roles.orderEdit]}>
          <Link to="/zamowienia/dodaj">
            <AddButton />
          </Link>
        </Authorized>

        <FilterButton onClick={() => showIncomplete()} />

        <StatusButtons orders={selectedOrders} onChange={() => fetchData()} />

        <DownloadOrdersButton
          orders={selectedOrders}
          onChange={() => fetchData()}
        />
      </Space>

      <Table<OrderRow>
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={(p, f) => handleTableChange(p, f)}
      />
    </Space>
  );
}
