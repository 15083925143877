import { useEffect, useState } from "react";
import { Outlet, useMatches } from "react-router-dom";
import { Layout } from "antd";

import AppHeader from "app/app-header";
import AppBreacrumb from "app/app-breadcrumb";

const { Content, Footer } = Layout;

export default function AppLayout() {

  const [hierarchy, setHierarchy] = useState<Hierarchy[]>([]);
  
  const matches = useMatches();
  useEffect(() => {
    const crumbs = matches
      .filter((match) => Boolean(match.handle?.crumb))
      .map((match) => ({path: match.pathname, name: match.handle.crumb(match.data)}));

    setHierarchy(crumbs);
  }, [matches]);

  return (
    <Layout>
      <AppHeader />
      <Content style={{ padding: '0 16px' }}>
        <AppBreacrumb hierarchy={hierarchy} />
        <div style={{ padding: "16px", background: "white" }}>
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Ravin ©2023</Footer>
    </Layout>
  );
}
